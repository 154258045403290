html {
    background-color: #060009;
    background-image: url("./media/background.webp");
    z-index: -9999;

}

@font-face {
    font-family: Oxanium;
    src: url("./media/Oxanium.ttf") format("opentype");
}

@font-face {
    font-family: Exo;
    src: url("./media/Exo2-Regular.ttf") format("opentype");
}


@font-face {
    font-family: Space Mono;
    src: url("./media/SpaceMono-Regular.ttf") format("opentype");
}

*, body {
    font-family: 'Oxanium', sans-serif;
}

.mono {
    font-family: 'Space Mono', sans-serif !important;
}

.exo {
    font-family: 'Exo', sans-serif !important;
}

.Mui-focused {
    border-color: #7700b3 !important;
}

.MuiMenu-list {
    background-color: #ffffff00;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    background-color: #1119 !important;
    margin-top: -15px;
    margin-left: -15px;
    backdrop-filter: blur(3px);
    border-radius: 20px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    border-width: 0px;
}

.MuiInput-underline::after {
    border-width: 0px !important;
}

#appbar {
    z-index: 10;
}

#cats {
    padding: 0px !important!
}

#box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    z-index: -1;
}

.DatePicker.Mui-active {
    background-color: #7700b3;
}

.DatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border-color: green;
}

#mui-1-label {
    font-size: 20px;
    color: white;
}

#mui-1 {
    border-color: #7700b3;
}

#box1 {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    z-index: 10;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#box2 {
    z-index: 100000;
    position: absolute;
}


a:link {
    transition: color 0.3s linear;
}

.App {
    z-index: 1;
    text-align: center;
    margin-top: 80px;
    background-color: transparent;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
    background-color: transparent;
    z-index: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
  color: #61dafb;
}


a:link {
    text-decoration: none;
      color: rgb(139, 38, 190) ;
      }
      /* visited link */
      a:visited {
        color: #7700b3;
      }
      /* mouse over link */
      a:hover {
        color: #b38600;
      }
      /* selected link */
      a:active {
        color: #806000;
      }

#altlink {
    text-decoration: none;
      color: #d5bc73 ;
      }
      /* visited link */
      #altlink:visited {
        color: #b38600;
      }
      /* mouse over link */
      #altlink:hover {
        color: #7700b3;
      }
      /* selected link */
      #altlink:active {
        color: rgb(139, 38, 190);
      }



@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
